import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast  } from 'vant';
import elementIcon from "../svgicon";
import moment from 'moment'
import {isPublic} from "@/service/pubilcPath";
const app = createApp(App)

app.use(router).use(Vant).use(elementIcon).use(Toast);
app.mount('#app')
app.config.globalProperties.$moment = moment
app.config.globalProperties.$showLoad = function (type) {
    if (type){
        Toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
        });
    }
    else {
        Toast.clear()
    }
};
/* 路由守卫 */
router.beforeEach((to, from, next) => {
  // 动态修改title
  if (to.meta.title) {
    document.title = to.meta.title
  }
      // next()

    let bingingPath = [
        '/customerBinding',
        '/customerInfo',
        '/documentaryRecord',
        '/singleSignOn',
    ]
  //登录守卫
  if (isPublic(to.path)) {
    next()
  }
  else if(bingingPath.includes(to.path)){
      next()
  }
  else {
    if ((sessionStorage.getItem('postID')) === null) {
        if (localStorage.getItem('style') === 'ding'){
            next({path: '/dingTalkLogin'})
        }else if(localStorage.getItem('style') === 'weChat'){
            next({path: '/weChatLogin'})
        }
    } else {
      next()
    }
    return
  }
})
