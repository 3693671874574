import {createRouter, createWebHashHistory} from "vue-router";
import Home from "../views/Home.vue";
import CustomerAdmin from "../components/customer_admin"; // 客户管理
import CustomerAdd from "../components/customer_add"; // 添加客户
import CustomerDetail from "../components/customer_detail"; // 客户详情
import Index from "../components/index";  // 首页
import WeightManage from "../components/weightManage"; // 权重管理
import WeightAdd from "../components/weightAdd";// 添加权重
import DemandTransfer from "../components/demandTransfer";// 客户转单
import LoginRecord from "../components/loginRecord";//登陆记录
import Journal from "../components/journal";// 日志
import AddRemarks from "../components/add_remarks";// 客户详情添加备注
import AddVisaHistory from "../components/add_visa_history";// 客户详情添加签证历史
import Documentary from "../components/documentary";// 跟单记录列表
import DocumentaryAdd from "../components/documentaryAdd";// 新增跟单记录列表
import SharedPool from "../components/sharedPool";// 共享池
import CustomAllocation from "../components/customAllocation";// 分配记录
import SignedResources from "../components/signedResources";// 已签约
import GroupData from "../components/groupData";// 组内数据
import GroupData2 from "../components/groupData2";// 组内数据（顾问）


import ReturnVisit from "../components/returnVisit";// 客户回访
import Login from "../components/login";// 登录
import Handover from "../components/handover";// 文案交接

import Approve from "../components/approve";// 交接审批

import ApproveOperation from "../components/approveOperation";// 交接审批操作

import ProjectManage from "../components/projectManage" //  项目管理列表
import ProjectEdit from "../components/projectEdit" // 项目管理修改
import DefaultOption from '../components/defaultOption' // 默认选项

import DefaultAdd from '../components/defaultAdd' // 默认选项
import DefaultEdit from '../components/defaultEdit' // 默认选项
import CustomSchedule from "../components/customSchedule";// 客户列表
import CostList from "../components/costList";// 客户费用
import CustomCostDetail from "../components/customCostDetail";// 客户费用添加/修改
import CopywritingList from "../components/copywritingList";// 文案进度
import CopywritingDetail from "../components/copywritingDetail";// 文案进度详情
import ApplySpeed from "../components/applySpeed";// 申请进度
import ApplySpeedDetail from "../components/applySpeedDetail";// 申请进度详情
import DetailShow from "../components/detailShow";// 客户详情列表
import WeChatLogin from "../components/weChatLogin";// 企业微信登录
import FoshanLogin from "../components/foshanLogin";// 佛山企业微信登录
import SignedHandover from "../components/signedHandover";// 已签约页的文案交接

import ProgressIndex from "../components/progressIndex";// 申请进度
import ProgressAdd from "../components/progressAdd";// 申请进度新增
import ProgressEdit from "../components/progressEdit";// 申请进度新增

import ContractAdmin from "../components/contractAdmin";// 合同管理
import ContractIndex from "../components/contractIndex";// 合同管理 客户列表
import ContractDown from "../components/contractDown";// 合同管理  下载

import HandoverList from "../components/handoverList";// 交接列表
import Daily from "../components/daily";// 日报
import DailyNew from "../components/dailyNew";
import SalesContractDaily from "../components/salesContractDaily";//销售部签约日报
// import DailyNew from "../components/dailyNew";// 日报新

import CustomerRelease from "../components/customerRelease";// 客户释放
import ChannelAdmin from "../components/channelAdmin";// 渠道管理
import ChannelEdit from "../components/channelEdit";// 渠道管理、

import Personal from "../components/personal";// 个人信息

import CodeEdit from "../components/codeEdit";// 编辑二维码
import WeChatAdd from "../components/WeChatAdd";// 编绑定微信

import QuarterPage from "../components/quarterPage";// 数量统计
import ConversionPage from "../components/conversionPage";// 转化率统计
import Analysis from "../components/analysis";// 类别统计


import HandoverIndex from "../components/handoverIndex";// 转案单详情

import ResourcesPage from "../components/resourcesPage";//资源统计
import TypeStatistics from "../components/typeStatistics";//签约统计
import EntryStatistics from "../components/entryStatistics";//入职两月签约统计
import PerformanceConfirmation from "../components/performanceConfirmation";//业绩确认确认页
import PerformanceHistory from "../components/performanceHistory";//业绩确认确认历史

import ErrorPage from "../components/errorPage";//java 授权失败跳转页面
import ErrorPageNew from "../components/errorPageNew";//java 钉钉授权失败跳转页面

import RobLogin from "../components/robLogin";//
import RobIndex from "../components/robIndex";//
import DingLogin from "../components/dingLogin";//钉钉免登
import DingTalkLogin from "../components/dingTalkLogin";//钉钉免登2

import notFound from "../components/notFound";// 404页面
import middle from "../components/middle";// 业绩确认中继页

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },

    {
        path: "/robLogin",
        name: "RobLogin",
        component: RobLogin,
    },

    // 抢单池
    {
        path: "/robIndex",
        name: "robIndex",
        component: RobIndex,
        meta: {
            title: '抢单池'
        }
    },
    // 客户管理
    {
        path: "/customManage",
        name: "CustomerAdmin",
        component: CustomerAdmin,
        meta: {
            title: '客户管理'
        }
    },
    // 登录
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: '登录'
        }
    },

    // 错误
    {
        path: "/errorPage",
        name: "errorPage",
        component: ErrorPage,
        meta: {
            title: '错误'
        }
    },

    // 钉钉错误页
    {
        path: "/errorPageNew",
        name: "errorPageNew",
        component: ErrorPageNew,
        meta: {
            title: '错误'
        }
    },

    // 企业微信登录
    {
        path: "/weChatLogin",
        name: "WeChatLogin",
        component: WeChatLogin,
        meta: {
            title: '登录'
        }
    },
    // 佛山登录
    {
        path: "/foshanLogin",
        name: "FoshanLogin",
        component: FoshanLogin,
        meta: {
            title: '登录'
        }
    },
    // 客户添加
    {
        path: "/customer_add",
        name: "CustomerAdd",
        component: CustomerAdd,
        meta: {
            title: '客户添加'
        }
    },
    // 首页
    {
        path: "/index",
        name: "Index",
        component: Index,
        meta: {
            title: '首页'
        }
    },
    // 客户详情
    {
        path: "/customer_detail",
        name: "CustomerDetail",
        component: CustomerDetail,
        meta: {
            title: '客户详情'
        }
    },
    //权重管理
    {
        path: "/weightManage",
        name: "WeightManage",
        component: WeightManage,
        meta: {
            title: '权重管理'
        }
    },
    //添加权重
    {
        path: "/weightAdd",
        name: "WeightAdd",
        component: WeightAdd,
        meta: {
            title: '权重详情'
        }
    },
    //客户转单
    {
        path: "/demandTransfer",
        name: "DemandTransfer",
        component: DemandTransfer,
        meta: {
            title: '客户转单'
        }
    },
    //登陆记录
    {
        path: "/loginRecord",
        name: "LoginRecord",
        component: LoginRecord,
        meta: {
            title: '登录记录'
        }
    },
    //日志
    {
        path: "/journal",
        name: "journal",
        component: Journal,
        meta: {
            title: '日志'
        }
    },
    //客户详情添加备注
    {
        path: "/add_remarks",
        name: "AddRemarks",
        component: AddRemarks,
        meta: {
            title: '日志'
        }
    },
    //客户详情添加签证历史
    {
        path: "/add_visa_history",
        name: "AddVisaHistory",
        component: AddVisaHistory,
        meta: {
            title: '签证历史详情'
        }
    },
    //跟单记录列表
    {
        path: "/documentary",
        name: "Documentary",
        component: Documentary,
        meta: {
            title: '跟单记录'
        }
    },
    //新增跟单记录
    {
        path: "/documentaryAdd",
        name: "DocumentaryAdd",
        component: DocumentaryAdd,
        meta: {
            title: '跟单记录详情'
        }
    },

    //共享池
    {
        path: "/sharedPool",
        name: "SharedPool",
        component: SharedPool,
        meta: {
            title: '共享池'
        }
    },
    //分配记录
    {
        path: "/customAllocation",
        name: "CustomAllocation",
        component: CustomAllocation,
        meta: {
            title: '分配记录'
        }
    },
    //已签约
    {
        path: "/signedResources",
        name: "SignedResources",
        component: SignedResources,
        meta: {
            title: '已签约资源'
        }
    },
    // 组内数据（渠道）
    {
        path: "/groupData",
        name: "groupData",
        component: GroupData,
        meta: {
            title: '组内数据'
        }
    },
    // 组内数据（顾问）
    {
        path: "/groupData2",
        name: "GroupData2",
        component: GroupData2,
        meta: {
            title: '组内数据'
        }
    },
    // 客户回访
    {
        path: "/returnVisit",
        name: "ReturnVisit",
        component: ReturnVisit,
        meta: {
            title: '客户回访'
        }
    },
    // 文案交接 handover
    {
        path: '/handover',
        name: 'handover',
        component: Handover,
        meta: {
            title: '文案交接'
        }
    },
    // 交接审批
    {
        path: '/approve',
        name: 'approve',
        component: Approve,
        meta: {
            title: '交接审批'
        }
    },
    // 3-31 项目管理 projectManage
    {
        path: '/projectManage',
        name: 'projectManage',
        component: ProjectManage,
        meta: {
            title: '项目管理'
        }
    },
    // 3-31 项目管理修改 projectEdit
    {
        path: '/projectEdit',
        name: 'projectEdit',
        component: ProjectEdit,
        meta: {
            title: '项目管理详情'
        }
    },

    // 默认选项 default
    {
        path: '/defaultOption',
        name: 'defaultOption',
        component: DefaultOption,
        meta: {
            title: '默认选项'
        }
    },

    // 默认选项 add
    {
        path: '/defaultAdd',
        name: 'defaultAdd',
        component: DefaultAdd,
        meta: {
            title: '默认选项详情'
        }
    },
    // 默认选项 edit 修改
    {
        path: '/defaultEdit',
        name: 'defaultEdit',
        component: DefaultEdit,
        meta: {
            title: '默认选项详情'
        }
    },

    // 交接审批操作
    {
        path: '/approveOperation',
        name: 'approveOperation',
        component: ApproveOperation,
        meta: {
            title: '交接审批'
        }
    },

    // 客户列表
    {
        path: '/customSchedule',
        name: 'customSchedule',
        component: CustomSchedule,
        meta: {
            title: '客户列表'
        }
    },

    // 费用列表
    {
        path: '/costList',
        name: 'costList',
        component: CostList,
        meta: {
            title: '客户费用'
        }
    },
    // 费用列表
    {
        path: '/customCostDetail',
        name: 'customCostDetail',
        component: CustomCostDetail,
        meta: {
            title: '客户费用详情'
        }
    },
    // 文案进度列表
    {
        path: '/copywritingList',
        name: 'copywritingList',
        component: CopywritingList,
        meta: {
            title: '文案进度'
        }
    },
    // 文案进度详情
    {
        path: '/copywritingDetail',
        name: 'copywritingDetail',
        component: CopywritingDetail,
        meta: {
            title: '文案进度详情'
        }
    },
    // 申请进度
    {
        path: '/applySpeed',
        name: 'applySpeed',
        component: ApplySpeed,
        meta: {
            title: '申请进度'
        }
    },
    // 申请进度详情
    {
        path: '/applySpeedDetail',
        name: 'applySpeedDetail',
        component: ApplySpeedDetail,
        meta: {
            title: '申请进度详情'
        }
    },
    // 客户详情列表
    {
        path: '/detailShow',
        name: 'detailShow',
        component: DetailShow,
        meta: {
            title: '客户详情列表'
        }
    },
    // 已签约页的交接页面
    {
        path: '/signedHandover',
        name: 'signedHandover',
        component: SignedHandover,
        meta: {
            title: '转案单'
        }
    },
    // 申请进度
    {
        path: '/progressIndex',
        name: 'progressIndex',
        component: ProgressIndex,
        meta: {
            title: '申请进度'
        },
    },
    // 申请进度新增
    {
        path: '/progressAdd',
        name: 'progressAdd',
        component: ProgressAdd,
        meta: {
            title: '新增申请进度'
        },
    },

    // 申请进度修改
    {
        path: '/progressEdit',
        name: 'progressEdit',
        component: ProgressEdit,
        meta: {
            title: '修改申请进度'
        },
    },

    // 合同管理 contractAdmin
    {
        path: '/contractAdmin',
        name: 'contractAdmin',
        component: ContractAdmin,
        meta: {
            title: '合同管理'
        },
    },

    // 合同管理详情 contractIndex
    {
        path: '/contractIndex',
        name: 'contractIndex',
        component: ContractIndex,
        meta: {
            title: '客户合同列表'
        },
    },

    // 合同管理下载文件 contractDown
    {
        path: '/contractDown',
        name: 'contractDown',
        component: ContractDown,
        meta: {
            title: '下载'
        },
    },

    // 交接列表
    {
        path: '/handoverList',
        name: 'handoverList',
        component: HandoverList,
        meta: {
            title: '转案'
        },
    },
    // 客户释放
    {
        path: '/customerRelease',
        name: 'customerRelease',
        component: CustomerRelease,
        meta: {
            title: '客户释放'
        },
    },
    // 渠道管理
    {
        path: '/channelAdmin',
        name: 'channelAdmin',
        component: ChannelAdmin,
        meta: {
            title: '渠道管理'
        },
    },
    // 渠道编辑
    {
        path: '/channelEdit',
        name: 'channelEdit',
        component: ChannelEdit,
        meta: {
            title: '渠道编辑'
        },
    },

    // 渠道编辑
    {
        path: '/personalSet',
        name: 'personalSet',
        component: Personal,
        meta: {
            title: '个人信息'
        },
    },
    // 编辑二维码
    {
        path: '/codeEdit',
        name: 'codeEdit',
        component: CodeEdit,
        meta: {
            title: '编辑二维码'
        },
    },
    // 绑定微信
    {
        path: '/weChatAdd',
        name: 'weChatAdd',
        component: WeChatAdd,
        meta: {
            title: '绑定企业微信'
        },
    },

    // 绑定微信
    {
        path: '/handoverIndex',
        name: 'handoverIndex',
        component: HandoverIndex,
        meta: {
            title: '转案单详情'
        },
    },
    // 数量统计
    {
        path: '/quarterPage',
        name: 'quarterPage',
        component: QuarterPage,
        meta: {
            title: '数量统计'
        },
    },
    // 转化率统计
    {
        path: '/conversionPage',
        name: 'conversionPage',
        component: ConversionPage,
        meta: {
            title: '转化率统计'
        },
    },
    // 类别统计
    {
        path: '/analysis',
        name: 'analysis',
        component: Analysis,
        meta: {
            title: '类别统计'
        },
    },


    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },

    // 日报
    {
        path: '/daily',
        name: 'daily',
        component: Daily,
        meta: {
            title: '日报'
        },
    },
    // 日报 new
    {
        path: '/dailyJava',
        name: 'dailyJava',
        component: DailyNew,
        meta: {
            title: '销售日报'
        },
    },
    // 销售部签约日报 new
    {
        path: '/salesContractDaily',
        name: 'salesContractDaily',
        component: SalesContractDaily,
        meta: {
            title: '销售部签约日报'
        },
    },
    //资源统计
    {
        path: '/resourcesPage',
        name: 'resourcesPage',
        component: ResourcesPage,
        meta: {
            title: '资源统计'
        },
    },
    //资源统计
    {
        path: '/typeStatistics',
        name: 'typeStatistics',
        component: TypeStatistics,
        meta: {
            title: '签约统计'
        },
    },
    //入职时间统计
    {
        path: '/entryStatistics',
        name: 'entryStatistics',
        component: EntryStatistics,
        meta: {
            title: '入职时间统计'
        },
    },
    //绩效确认-确认端
    {
        path: '/performanceConfirmation',
        name: 'performanceConfirmation',
        component: PerformanceConfirmation,
    },
    // 绩效确认-确认端 历史记录
    {
        path: '/performanceHistory',
        name: 'performanceHistory',
        component: PerformanceHistory
    },
    // 钉钉免登
    {
        path: '/dingLogin',
        name: 'dingLogin',
        component: DingLogin,
        meta: {
            title: '钉钉免登'
        },
    },
    // 钉钉免登2
    {
        path: '/dingTalkLogin',
        name: 'dingTalkLogin',
        component: DingTalkLogin,
        meta: {
            title: '钉钉免登'
        },
    },
    // 客户绑定
    {
        path: '/customerBinding',
        name: 'customerBinding',
        component: () => import(/* webpackChunkName: "about" */ '../bindingView/customerBinding.vue'),
        meta: {
            title: '客户绑定'
        },
    },
    // 客户信息
    {
        path: '/customerInfo',
        name: 'customerInfo',
        component: () => import(/* webpackChunkName: "about" */ '../bindingView/customerInfo.vue'),
        meta: {
            title: '客户信息'
        },
    },

    // 跟单记录
    {
        path: '/documentaryRecord',
        name: 'documentaryRecord',
        component: () => import(/* webpackChunkName: "about" */ '../bindingView/documentaryRecord.vue'),
        meta: {
            title: '跟单记录'
        },
    },
    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component: () => import(/* webpackChunkName: "group-foo" */"@/bindingView/singleSignOn"),
        meta: {
            title: '登录页面'
        },
    },

    {
        path: '/middle',
        name: 'middle',
        component: middle,
    },


    // 404页面
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: notFound,
        meta: {
            title: '404'
        },
    },

];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;
